import _         from "lodash";
import * as globals from "./globals.js"
import param     from "jquery-param";
import {route}   from "ziggy";
import {Ziggy}   from "resources/assets/js/ziggy";

/**
 * Generates a route using its name as defined in the Laravel controllers, using
 * default parameters merged with any others passed in.
 *
 * @param {String}        name
 * @param {Object}        parameters Route replacement parameters
 * @param {Object|string} query      URL query parameters
 * @param {Boolean}       absolute   Includes the origin in the URL
 */
export function get(name, parameters = {}, query = {}, absolute = false) {
    let defaults = {
        "f": globals.getActiveFarmCode(),
    };

    /**
     * Add default parameters where not already set.
     */
    parameters = _.defaults({}, parameters, defaults);

    /**
     * Remove all null/undefined parameters.
     */
    parameters = _.omitBy(parameters, _.isNil);

    let queryParams = {};

    /**
     * param is just a replica of $.param
     */
    if (_.isString(query)) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        queryParams = Object.fromEntries(urlSearchParams.entries());
        queryParams = param(_.omitBy(queryParams, _.isNil));
    }

    /**
     * Because Ziggy route function no longer uses with / withQuery we now need to combine
     * the params and querystring values.
     * See: https://github.com/tighten/ziggy/blob/main/UPGRADING.md#user-content-with-withquery-removed
     */
    const finalParameters = _.merge(parameters, queryParams);

    /**
     * Get the route from the registry (generated by artisan)
     */
    let url = route(name, finalParameters, false, Ziggy);

    /**
     * Make sure we fail when a route could not be resolved.
     */
    if ( ! url) {
        throw new Error("Route not found: " + JSON.stringify({name, parameters}));
    }

    /**
     * Remove the default query string that is created using unused parameters.
     */
    let path = url.split("?")[0];

    /**
     * Make sure query is a string before we return it.
     */
    if (_.isObject(query)) {
        query = param(_.omitBy(query, _.isNil));
    }

    let result = query ? `${path}?${query}` : path;

    if (!absolute) {
        return result;
    }

    return window.location.origin + result;
}
